import request from '@/utils/request'

export function getTagTreeBuCustomer(data) {
  return request({
    url: 'chance-web-api/admin/chance/getTagTreeBuCustomer',
    method: 'post',
    data,
  })
}
//  获取标签树
export function getTagTree(data) {
  return request({
    url: 'tag-web-api/admin/tag/getTagTree',
    method: 'POST',
    data,
  })
}
