<template>
  <div class="chose-samples">
    <div class="sample-list" style="width: 66%; overflow: auto">
      <div class="search">
        <el-form ref="searchForm" :model="searchForm" :show-message="false" size="small" inline>
          <el-form-item>
            <el-select v-model="searchForm.categoryId" placeholder="样片类型" style="width: 150px">
              <el-option value>全部</el-option>
              <el-option
                v-for="item in categoryList"
                :key="item.categoryId"
                :value="item.categoryId"
                :label="item.categoryName"
              ></el-option>
            </el-select>
          </el-form-item>
          <el-form-item style="width: 150px" prop="sampleCode">
            <el-input v-model="searchForm.sampleCode" placeholder="样片ID" />
          </el-form-item>
          <el-form-item prop="sampleName" style="width: 150px">
            <el-input v-model="searchForm.sampleName" placeholder="样片名称" />
          </el-form-item>
          <el-form-item prop="isAuthorize" style="width: 150px">
            <el-select v-model="searchForm.isAuthorize" placeholder="是否授权">
              <!-- <el-option value="" label="全部"></el-option> -->
              <el-option :value="1" label="是"></el-option>
              <el-option :value="0" label="否"></el-option>
            </el-select>
          </el-form-item>
          <div class="tags-wrapper">
            <el-button type="text" @click="tagVisible = true">点击选择标签</el-button>
            <el-button size="small" @click="handleSearch">查询</el-button>
            <el-button size="small" @click="handleReset">重置</el-button>
          </div>
          <div class="tags">
            <el-tag
              v-for="(tag, index) in searchForm.productTagValues"
              :key="tag.tagValueId"
              closable
              style="margin-right: 10px; margin-bottom: 10px"
              :style="`color:#000;border:0;background-color:${tag.colour}`"
              @close="() => handleTagClose(index)"
              >{{ tag.tagValue }}</el-tag
            >
          </div>
        </el-form>
      </div>
      <vxe-grid
        :ref="tableRef"
        v-loading="loading"
        :row-id="rowId"
        border
        :data="dataSource"
        :columns="tableColumn"
        height="500"
        size="small"
        v-bind="tableOptions"
        @checkbox-change="handleCheckboxChange"
        @checkbox-all="handleCheckboxAll"
      >
        <template #img="{ row }">
          <el-image
            style="width: 50px; height: 50px"
            :src="row.coverPictureUrl"
            :preview-src-list="[row.coverPictureUrl]"
          ></el-image>
        </template>
        <template #pager>
          <div class="pagination-wrapper">
            <div class="">
              <el-pagination
                background
                :current-page="iPagination.currentPage"
                :page-sizes="[10, 20, 30, 40]"
                :page-size="iPagination.pageSize"
                layout="total,prev, pager, next ,sizes,jumper"
                :total="iPagination.total"
                @size-change="handleSizeChange"
                @current-change="handlePageChange"
              >
              </el-pagination>
            </div>
          </div>
        </template>
      </vxe-grid>
      <set-tag-mange
        ref="TagMange"
        :append-to-body="true"
        :visible="tagVisible"
        :value="searchForm.tagList"
        @ok="tagOk"
        @cancel="tagCancel"
      />
    </div>

    <div class="current-select-Sample" style="width: 33%; overflow: auto">
      <div class="title">
        <div class="number">
          <span class="num">{{ selectNumber }}</span
          >/<span class="num">{{ total }}</span>
        </div>
        <!-- <div class="clear" @click="clearAll">清空</div> -->
      </div>
      <div class="current-select-list">
        <el-table :data="currenSelected" border size="small">
          <el-table-column prop="sampleCode" label="样片ID"> </el-table-column>
          <el-table-column prop="sampleName" label="样片名称"> </el-table-column>
          <el-table-column label="操作">
            <template slot-scope="scope">
              <el-button type="text" size="small" @click="remove(scope.row.sampleId)"
                >删除</el-button
              >
            </template>
          </el-table-column>
        </el-table>
        <!--      <div class="current-select-item" v-for="item in currenSelected" :key="item.sampleId">-->
        <!--        <div class="name">{{item.sampleName}}/{{item.categoryName}}</div>-->
        <!--        <el-button type="text" class="delete" @click="remove(item.sampleId)">删除</el-button>-->
        <!--      </div>-->
      </div>
    </div>
  </div>
</template>

<script>
import setTagMange from '@/components/tag/setTagMange.vue'
import { unionBy, remove, includes } from 'lodash'
// import TableListMixin from "@/minixs/TableListMinix";
import { getSampleTypeTree, getSampleList } from '@/api/sample'
// import { postAction, deleteAction } from "@/api/manage";

export default {
  components: {
    setTagMange,
  },
  props: {
    currentQuestion: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    return {
      categoryList: [], // 分类；分类类表
      queryParams: {
        auditStatus: 2,
        isDisable: 1,
      },
      tagVisible: false,
      defaultForm: {
        categoryId: '',
        sampleCode: '',
        sampleName: '',
        isAuthorize: '',
        productTagValues: [],
        tagList: [],
      },
      searchForm: {
        sampleCode: '',
        categoryId: '',
        sampleName: '',
        isAuthorize: '',
        productTagValues: [],
        tagList: [],
      },
      rowId: 'sampleCode',
      tableRef: 'SampleList',
      tableColumn: [
        { type: 'checkbox', width: 50, align: 'center' },
        { field: 'sampleCode', title: '样片ID' },
        { field: 'sampleName', title: '样片名称' },
        { field: 'categoryName', title: '样片类型' },
        {
          field: 'coverPictureUrl',
          title: '封面图',
          slots: { default: 'img' },
          width: 70,
        },
      ],

      url: {
        list: 'product-web-api/admin/sample/list',
      },
      tableOptions: {
        checkboxConfig: {
          checkRowKeys: [],
          reserve: true, // 保留勾选状态
        },
      },
      checkedRows: [],
      currenSelected: [],
      iPagination: {
        pageSize: 10,
        currentPage: 1,
        total: 0,
      },
      dataSource: [],
      loading: false,
      total: 0,
    }
  },
  computed: {
    selectNumber() {
      return (this.currenSelected || []).length
    },
  },
  watch: {
    checkedRows(newVal) {
      this.currenSelected = newVal
    },
  },
  created() {
    this.loadData()
    this.getSampleCategory()
    this.currenSelected = this.currentQuestion.samples.map((el) => {
      return {
        coverPictureUrl: el.relatedSampleUrl,
        sampleName: el.relatedSampleName,
        sampleCode: el.relatedSampleCode,
      }
    })
  },
  methods: {
    async loadData() {
      try {
        this.loading = true
        const params = {
          pageNumber: this.iPagination.currentPage,
          pageSize: this.iPagination.pageSize,
          data: { ...this.searchForm, ...this.queryParams },
        }
        this.dataSource = []
        const res = await getSampleList(params)
        if (res.success === false) {
          this.$message.error(res.description)
        }
        this.loading = false
        this.iPagination.total = parseInt(res.data.total, 10)
        this.dataSource = res.data.content

        this.total = parseInt(res.data.total, 10)
        this.$nextTick(() => {
          const data = this.currenSelected || []
          this.checkedRows = [...data]
          this.$refs.SampleList.setCheckboxRow(data, true)
        })
      } catch (error) {
        this.loading = false
        console.log(error)
      }
    },
    handleSearch() {
      this.loadData()
    },
    handleReset() {
      this.searchForm.categoryId = ''
      this.searchForm.isAuthorize = ''
      this.searchForm.sampleName = ''
      this.searchForm.categoryId = ''
      this.iPagination.currentPage = 1
      this.searchForm.sampleCode = ''
      this.searchForm.tagList = []
      this.searchForm.productTagValues = []
      this.resetSelected()
      this.loadData()
    },
    async getSampleCategory() {
      try {
        const res = await getSampleTypeTree({
          data: { isDisable: 1, auditStatus: 2 },
        })
        if (res.success === false) {
          this.$message.error(res.description)
          return
        }
        this.categoryList = res.data

        // this.$nextTick(() => {
        //   this.$refs.SampleList.setCheckboxRow([...this.activeRow], true);
        //   this.checkedRows = this.activeRow;
        // });
      } catch (e) {}
    },
    resetSelected() {
      this.checkedRows = []
      this.tableOptions.checkboxConfig.checkRowKeys = []
      this.$refs[this.tableRef].clearCheckboxRow()
    },
    handleCheckboxAll({ records, reserves, indeterminates, checked, row, rowIndex }) {
      const selectIds = this.checkedRows.map((item) => item.sampleId)
      if (checked) {
        const addRows = []
        records.forEach((item) => {
          if (!includes(selectIds, item.sampleId)) {
            addRows.push(item)
          }
        })
        this.checkedRows = [...this.checkedRows, ...addRows]
      } else {
        this.dataSource.forEach((opt) => {
          this.checkedRows = remove(
            this.checkedRows,
            (item) => item[this.rowId] !== opt[this.rowId],
          )
        })
      }
      this.tableOptions.checkboxConfig.checkRowKeys = this.checkedRows.map(
        (item) => item[this.rowId],
      )
    },
    tagOk(e) {
      this.searchForm.tagList = e.map((i) => i.id)
      this.searchForm.productTagValues = e.map((i) => ({
        // productId: this.searchForm.productId,
        tagValueId: i.id,
        tagValue: i.name,
        colour: i.colour,
      }))
      this.tagVisible = false
    },
    tagCancel() {
      this.tagVisible = false
    },
    handleTagClose(index) {
      this.searchForm.productTagValues = this.searchForm.productTagValues.filter(
        (_, i) => i !== index,
      )
      this.searchForm.tagList.splice(index, 1)
      this.$refs.TagMange.repeatSelect()
    },
    clearAll() {
      // this.$emit("clearAll");
      this.$refs[this.tableRef].clearCheckboxRow()
    },
    remove(id) {
      // this.$emit("delete", id);
      const ids = this.currenSelected.filter((item) => item.sampleId !== id)
      const newSelected = this.currenSelected.filter((item) => item.sampleId !== id)
      const removeItem = this.currenSelected.find((item) => item.sampleId === id)
      this.checkedRows = newSelected
      this.$refs.SampleList.toggleCheckboxRow(removeItem)
      this.tableOptions.checkboxConfig.checkRowKeys = ids
    },
    handleSizeChange(pageSize) {
      this.iPagination.pageSize = pageSize
      this.loadData()
    },
    handlePageChange(current) {
      this.iPagination.currentPage = current
      this.loadData()
    },
    handleCheckboxChange({ records, reserves, indeterminates, checked, row, rowIndex }) {
      if (checked) {
        this.checkedRows = unionBy([...this.checkedRows, row])
      } else {
        this.checkedRows = remove(this.checkedRows, (item) => item[this.rowId] !== row[this.rowId])
      }

      this.tableOptions.checkboxConfig.checkRowKeys = this.checkedRows.map(
        (item) => item[this.rowId],
      )
    },
  },
}
</script>

<style lang="scss" scoped>
.chose-samples {
  width: 100%;
  display: flex;

  .sample-list {
    flex: 1;
    border-right: 1px solid #e0e0e0;
    padding-right: 20px;

    .pagination-wrapper {
      display: flex;
      padding: 30px;
      justify-content: space-between;
    }
  }
  .tags-wrapper {
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    align-items: center;
    padding-right: 50px;
  }

  .tags {
    padding-top: 10px;
    overflow: auto;
    border-top: 1px solid #e0e0e0;
    border-bottom: 1px solid #e0e0e0;
    margin-bottom: 20px;
  }
  .tags::-webkit-scrollbar {
    width: 5px;
    height: 10px;
    /**/
  }
  .tags::-webkit-scrollbar-track {
    background: #fff;
    border-radius: 5px;
    display: none;
  }
  .tags::-webkit-scrollbar-thumb {
    background: #bfbfbf;
    border-radius: 10px;
  }

  .current-select-Sample {
    padding: 0 20px;
    width: 290px;
    box-sizing: border-box;
    .title {
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding-bottom: 15px;
      padding-top: 15px;
      .number {
        font-size: 14px;
        font-weight: 500;
        color: #000000;
        line-height: 20px;
      }
    }
    .current-select-list {
      .current-select-item {
        display: flex;
        justify-content: space-between;
        align-items: center;
        .name {
          color: #000;
          font-size: 14px;
          line-height: 22px;
        }
      }
    }
  }
}
</style>
