import request from '@/utils/request'

export function getQuestionnaireLists(data) {
  return request({
    url: 'marketing-web-api/supplier/questionnaire/user/list',
    method: 'post',
    data,
  })
}

export function sendInAdvance(data) {
  return request({
    url: 'marketing-web-api/supplier/questionnaire/user/sendInAdvance',
    method: 'post',
    data,
  })
}

export function resend(data) {
  return request({
    url: 'marketing-web-api/supplier/questionnaire/user/resend',
    method: 'post',
    data,
  })
}

export function getAllQuestionnaire(data) {
  return request({
    url: 'marketing-web-api/admin/questionnaire/pageList',
    method: 'post',
    data,
  })
}

export function sendDirectly(data) {
  return request({
    url: 'marketing-web-api/supplier/questionnaire/user/sendDirectly',
    method: 'post',
    data,
  })
}

export function getQuestionnaireDetail(data) {
  return request({
    url: 'marketing-web-api/supplier/questionnaire/user/detail',
    method: 'post',
    data,
  })
}

export function getQuestionDetail(data) {
  return request({
    url: 'marketing-web-api/api/questionnaire/problem/list',
    method: 'post',
    data,
  })
}

export function updateQuestion(data) {
  return request({
    url: 'marketing-web-api/supplier/questionnaire/user/update',
    method: 'post',
    data,
  })
}
