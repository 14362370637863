<template>
  <div class="modify-question">
    <div class="title">
      {{ currentQuestionCopy.content }}
      <span style="color: #999999">({{ type[currentQuestionCopy.type] }})</span>
    </div>

    <div class="options">
      <template v-if="currentQuestionCopy.type == 0">
        <el-radio v-for="(item, index) in questionDetail.options" :key="index" v-model="currentQuestionCopy.problemResult" class="options-item" :label="item.option">{{
          item.option
        }}</el-radio>
      </template>

      <el-checkbox-group v-if="currentQuestionCopy.type == 1" v-model="currentQuestionCopy.problemResultArray" style="display: flex; flex-direction: column">
        <el-checkbox v-for="(item, index) in questionDetail.options" :key="index" class="options-item" :label="item.option"></el-checkbox>
      </el-checkbox-group>

      <el-date-picker
        v-if="currentQuestionCopy.type == 2"
        v-model="currentQuestionCopy.problemResult"
        type="date"
        placeholder="选择日期"
        format="yyyy 年 MM 月 dd 日"
        value-format="yyyy-MM-dd"
      ></el-date-picker>

      <el-input v-if="currentQuestionCopy.type == 3" v-model="currentQuestionCopy.problemResult" maxlength="30" show-word-limit></el-input>
    </div>
  </div>
</template>

<script>
import { getQuestionDetail } from '@/api/questionnaire'

export default {
  props: {
    currentQuestion: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    return {
      questionDetail: {},
      type: ['单选', '多选', '日期编辑', '填空'],
      currentQuestionCopy: {},
    }
  },
  watch: {
    currentQuestion: {
      handler(val) {
        const copyObj = JSON.parse(JSON.stringify(val))

        if (Number(copyObj.type) === 1) {
          copyObj.problemResultArray = copyObj.problemResult.split(',')
        }

        this.currentQuestionCopy = copyObj
      },
      deep: true,
      immediate: true,
    },
  },
  async mounted() {
    if (this.currentQuestion.problemId) {
      const reqObj = {
        data: {
          problemId: this.currentQuestion.problemId,
        },
      }

      const res = await getQuestionDetail(reqObj)

      if (res.success) {
        this.questionDetail = res.data[0]
      }
    }
  },
}
</script>

<style lang="scss" scoped>
.modify-question {
  padding: 0 28px;

  .options {
    display: flex;
    flex-direction: column;
    margin-top: 24px;
    padding: 0 8px;

    .options-item {
      margin-bottom: 24px;
    }
  }
}
</style>
