import request from '@/utils/request'

//  获取样品分类列表
export function getSampleTypeTree(data) {
  return request({
    url: 'product-web-api/admin/sampleCategory/list',
    method: 'POST',
    data,
  })
}

/**
 * 获取样品列表
 * @param {*} data
 * @returns
 */
export function getSampleList(data) {
  return request({
    url: 'product-web-api/admin/sample/list',
    method: 'POST',
    data,
  })
}
