<template>
  <div class="questionnaire-detail">
    <div class="header">
      <span class="title">问卷基础信息</span>
      <el-button icon="el-icon-back" size="mini" @click="goBack">返回</el-button>
    </div>
    <div class="body">
      <div class="body-item">
        <div class="body-header">基础信息</div>
        <div class="body-content">
          <!-- <div></div>
        <div></div> -->
          <el-descriptions>
            <el-descriptions-item label="手机号">{{ form.userPhone }}</el-descriptions-item>
            <el-descriptions-item label="发送时间">{{ form.sendTime }}</el-descriptions-item>
          </el-descriptions>
          <el-descriptions>
            <el-descriptions-item label="服务订单">{{ form.serviceNo }}</el-descriptions-item>
            <el-descriptions-item label="提交时间">{{ form.submitTime }}</el-descriptions-item>
          </el-descriptions>
        </div>
      </div>

      <div class="body-item">
        <div class="body-header">答题信息</div>
        <div class="body-content">
          <el-table :data="form.results" border>
            <el-table-column label="问题内容" prop="content"></el-table-column>
            <el-table-column label="已选答案">
              <template slot-scope="scope">
                <div
                  v-if="
                    scope.row.problemType != 3 && scope.row.problemType != 4 && scope.row.type !== 4
                  "
                >
                  {{ scope.row.problemResult }}
                </div>
                <div v-if="scope.row.problemType == 4">
                  <el-button type="text" @click="gotoRelatives">查看全部拍摄人</el-button>
                </div>
                <div v-if="scope.row.type === 4">
                  <el-image
                    v-for="(item, index) in handlerImage(scope.row.problemResult)"
                    :key="index"
                    class="image"
                    :src="item"
                    fit="cover"
                    :preview-src-list="handlerImage(scope.row.problemResult)"
                  ></el-image>
                </div>
                <div v-else>
                  <div v-for="(item, index) in scope.row.samples" :key="index">
                    <span
                      >{{ item.relatedSampleCode }}—{{ item.relatedSampleName }}*{{
                        item.relatedSampleNum
                      }}；</span
                    >
                    <span v-if="item.tagType == 1" style="color: #67c23a; margin-left: 10px"
                      >[已拍]</span
                    >
                  </div>
                </div>
              </template>
            </el-table-column>
            <el-table-column label="问题类型" width="120">
              <template slot-scope="scope">
                {{ problemType[scope.row.problemType] }}
              </template>
            </el-table-column>
            <el-table-column label="问题题型" width="120">
              <template slot-scope="scope">
                {{ type[scope.row.type] }}
              </template>
            </el-table-column>
            <el-table-column label="关联客户字段/标签分类" width="180">
              <template slot-scope="scope">
                <span v-if="scope.row.relatedUserFieldNote" style="margin-right: 10px">{{
                  scope.row.relatedUserFieldNote
                }}</span>
                <span v-if="scope.row.relatedTagValue">{{ scope.row.relatedTagValue }}</span>
              </template>
            </el-table-column>
            <el-table-column label="操作" width="120">
              <template slot-scope="scope">
                <span
                  v-if="scope.row.problemType !== 3 && scope.row.type !== 4"
                  class="text-button"
                  @click="openModify(scope.row)"
                  >修改</span
                >
                <el-button v-else type="text" disabled>修改</el-button>
              </template>
            </el-table-column>
          </el-table>
        </div>
      </div>
    </div>

    <el-dialog
      :title="currentRow.problemType != 3 ? '更改问题答案' : '选择样片'"
      :visible.sync="choseQuestionDialog"
      destroy-on-close
      :before-close="dialogClose"
      :width="currentRow.problemType != 3 ? '50%' : '80%'"
    >
      <modify-question
        v-if="Object.keys(currentRow).length && currentRow.problemType != 3"
        ref="modifyQuestion"
        :current-question="currentRow"
      ></modify-question>

      <chose-samples
        v-if="Object.keys(currentRow).length && currentRow.problemType == 3"
        ref="choseSamples"
        :current-question="currentRow"
      ></chose-samples>

      <div slot="footer" class="dialog-footer">
        <el-button size="mini" @click="dialogCancel">取消</el-button>
        <el-button type="primary" size="mini" @click="dialogSubmit">确定</el-button>
      </div>
    </el-dialog>
    <SelectRelativesDrawer
      :show-drawer.sync="showDrawer"
      :service-id="serviceId"
      :biz-line-id="bizLineId"
      :customer-id="customerId"
    />
  </div>
</template>

<script>
import { getQuestionnaireDetail, updateQuestion } from '@/api/questionnaire'
import modifyQuestion from './modifyQuestion.vue'
import choseSamples from './choseSamples.vue'

export default {
  components: {
    modifyQuestion,
    choseSamples,
  },
  data() {
    return {
      questionnaireUserId: '',
      form: {},
      type: ['单选题', '多选题', '日期编辑题', '填空题', '图片编辑题'],
      problemType: ['自定义', '数据字典', '标签库', '拍摄产品', '拍摄人'],
      choseQuestionDialog: false,
      currentRow: {},
      showDrawer: false,
      serviceId: '',
      customerId: '',
      bizLineId: '',
    }
  },
  created() {
    this.serviceId = this.$route.query.serviceId
    this.customerId = this.$route.query.customerId
  },
  async mounted() {
    if (this.$route.query.questionnaireUserId) {
      this.questionnaireUserId = this.$route.query.questionnaireUserId
      await this.getTableData()
    }

    if (this.$route.query.bizLineId) {
      this.bizLineId = this.$route.query.bizLineId
    }
  },
  methods: {
    handlerImage(imgStr) {
      if (imgStr) {
        return imgStr.split(',')
      }
      return []
    },
    async getTableData() {
      const res = await getQuestionnaireDetail({
        data: this.questionnaireUserId,
      })
      if (res.success) {
        this.form = res.data
      }
    },
    openModify(row) {
      this.currentRow = row
      this.choseQuestionDialog = true
    },
    dialogClose(done) {
      done()
      this.currentRow = {}
    },
    dialogCancel() {
      this.choseQuestionDialog = false
      this.currentRow = {}
    },
    async dialogSubmit() {
      let reqObj = {}
      if (this.currentRow.problemType !== 3) {
        reqObj = {
          data: {
            tyQuestionnaireUserId: this.$route.query.questionnaireUserId,
            problemResult:
              this.$refs.modifyQuestion.currentQuestionCopy.type === 1
                ? this.$refs.modifyQuestion.currentQuestionCopy.problemResultArray.join()
                : this.$refs.modifyQuestion.currentQuestionCopy.problemResult,
            productId: this.$route.query.productId,
            problemId: this.currentRow.problemId,
          },
        }
      } else {
        const samplesCopy = this.$refs.choseSamples.currenSelected.map((el) => ({
          relatedSampleCode: el.sampleCode,
          relatedSampleName: el.sampleName,
          relatedSampleUrl: el.coverPictureUrl,
          relatedSampleNum: 1,
        }))

        reqObj = {
          data: {
            tyQuestionnaireUserId: this.$route.query.questionnaireUserId,
            productId: this.$route.query.productId,
            problemId: this.currentRow.problemId,
            problemResult: '',
            samples: samplesCopy,
          },
        }
      }

      const res = await updateQuestion(reqObj)
      if (res.success) {
        this.$message.success('修改成功')
        this.dialogCancel()
        this.getTableData()
      }
    },
    goBack() {
      this.$router.go(-1)
    },
    gotoRelatives() {
      this.showDrawer = true
    },
  },
}
</script>

<style lang="scss" scoped>
.questionnaire-detail {
  height: 100%;
  width: 100%;
  background: #fff;

  .header {
    padding: 10px 16px;
    display: flex;
    justify-content: space-between;
    border-bottom: 1px solid #ededed;

    .title {
      // font-size: 14px;
      position: relative;
      line-height: 30px;
    }

    .title::before {
      content: '';
      height: 18px;
      border-left: 3px solid #000;
      border-top: 1px solid transparent;
      border-bottom: 1px solid transparent;
      left: -14px;
      bottom: 6px;
      position: absolute;
    }
  }

  .body {
    padding: 32px 30px;

    .body-item {
      border-bottom: 1px solid #ededed;
      padding-bottom: 12px;
      margin-bottom: 24px;

      .body-header {
        padding-left: 13px;
        position: relative;
      }

      .body-header::before {
        height: 14px;
        content: '';
        width: 3px;
        background: #000;
        position: absolute;
        left: 0;
        bottom: 3px;
      }

      .body-content {
        padding-top: 24px;
      }

      ::v-deep .el-descriptions-item__label {
        color: #9a9d9e;
      }
    }

    .body-item:last-of-type {
      border-bottom: unset;
    }
  }

  ::v-deep .el-dialog {
    border-radius: 6px;

    .el-dialog__header {
      border-bottom: 1px solid #ededed;
    }

    .el-dialog__footer {
      border-top: 1px solid #ededed;
    }
  }

  .text-button {
    cursor: pointer;
    color: #2861e2;
  }
}
.image {
  margin-right: 12px;
  margin-bottom: 12px;
  width: 100px;
  height: 100px;
  border-radius: 8px;
  overflow: hidden;
}
</style>
